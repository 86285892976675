import Flexbox from 'components/Flexbox';
import Markdown from 'components/Markdown';
import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';

const classes = classNames.bind(styles);

interface PRDPreviewProps {
    value: string | string[] | null;
}

const PRDPreviewItem: FC<PRDPreviewProps> = ({ value }) => {
    return (
        <Flexbox vertical className={classes('prdPreviewItem')}>
            {typeof value === 'string' ? (
                <Markdown text={value?.replaceAll('```html', '').replaceAll('```', '')} />
            ) : Array.isArray(value) ? (
                <>
                    {value.map((textItem, textItemIdx) => (
                        typeof textItem === 'string' ? <Markdown key={textItemIdx} text={textItem} /> : null
                    ))}
                </>
            ) : null}
        </Flexbox>
    )
}

export default PRDPreviewItem